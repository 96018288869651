import SearchBox from "@components/main/SearchBox";
import BannerA from "@components/main/BannerA";
import ListC from "@components/main/ListC";
import BannerB from "@components/main/BannerB";
import ListE from "@components/main/ListE";
import BannerD from "@components/main/BannerD";
import BannerE from "@components/main/BannerE";
import ListG from "@components/main/ListG";
import styled from "styled-components";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React, { useEffect, useState } from "react";
import { getMainSearch } from "@modules/api/Search";
import Layout from "../components/layout";
import { Contains } from "@modules/utils/Contains";
import { useRouter } from "next/router";
import CookieConsent from "../components/cookie/CookieConsent";
import { getMyProfile } from "@modules/api/Crew";
import Head from "next/head";
import { setCookie } from "cookies-next";
import { convertDomain, getDomain } from "@modules/utils/AccessToken";
import { useTablet } from "@modules/hooks/useMedia";
import TTBBFooterLogo from "@public/images/common/img_tripbtoz.svg";
import { Cookies } from "react-cookie-consent";
import { IsTTBBApp } from "@modules/utils/TripbtozApp";
import ListD from '@components/main/ListD';
import ListA from '@components/main/ListA';
import Popup from '@components/common/popup';
import { getLocalStorage, setLocalStorage } from '@modules/utils/storage';

/* 다국어 설정 및 서버 데이터 호출 */
export async function getServerSideProps(context) {
  const {
    access_token,
    refresh_token
  } = context.query;
  const {
    req,
    res
  } = context;
  let authorization = context.req.headers["Authorization"];
  if (!authorization) {
    authorization = context.req.headers["authorization"];
  }
  if (access_token && refresh_token) {
    const domain = convertDomain(req.headers["host"]);
    setCookie("token_type", "Bearer", {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain
    });
    setCookie("access_token", access_token, {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain
    });
    setCookie("refresh_token", refresh_token, {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24 * 90,
      domain: domain
    });
    getMyProfile(context.locale).then(profile => {
      setCookie("user_id", profile?.body.item.id, {
        req,
        res,
        path: "/",
        maxAge: 60 * 60 * 24 * 90,
        domain: domain
      });
    });
  }
  if (authorization) {
    const tokenArr = authorization.split(" ");
    const domain = convertDomain(req.headers["host"]);
    setCookie("token_type", tokenArr[0], {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain
    });
    setCookie("access_token", tokenArr[1], {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain
    });
  }
  return {
    props: {
      ...(await serverSideTranslations(context.locale, ["common"])),
      locale: context.locale,
      access_token: access_token || null,
      refresh_token: refresh_token || null
    }
  };
}
export default function Main({
  locale,
  access_token,
  refresh_token
}) {
  const router = useRouter();
  const [mainBannerData, setMainBannerData] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const isTablet = useTablet();
  useEffect(() => {
    if (access_token && refresh_token) {
      localStorage.setItem("refresh_token", refresh_token);
      const returnUrl = localStorage.getItem("returnUrl");
      localStorage.removeItem("returnUrl");
      if (returnUrl) {
        router.push(returnUrl);
      } else {
        router.replace(`/`);
      }
    } else {
      localStorage.removeItem("returnUrl");
    }
  }, []);
  useEffect(() => {
    if (IsTTBBApp()) {
      window.signInResultViaApp = async json => {
        let item = json;
        if (typeof json === "string") {
          item = JSON.parse(json);
        }
        Cookies.set("user_id", item.userId, {
          expires: 1 / 24,
          domain: getDomain()
        });
        const tokenArr = item.token.split(" ");
        Cookies.set("token_type", tokenArr[0], {
          expires: 1 / 24,
          domain: getDomain()
        });
        Cookies.set("access_token", tokenArr[1], {
          expires: 1 / 24,
          domain: getDomain()
        });
        router.reload();
      };
    }
  }, []);

  // 데이터 호출
  useEffect(() => {
    getMainSearch(locale).then(data => setMainBannerData(data.body));
  }, [locale]);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  useEffect(() => {
    if (!router.isReady) return;
    // const popupShow = shouldShowPopup();
    const popupShow = true;
    setPopupOpen(popupShow);
  }, [router.isReady]);
  const shouldShowPopup = () => {
    const popupClosedDate = getLocalStorage('popupClosedDate');
    if (!popupClosedDate) {
      return true; // 저장된 날짜가 없으면 팝업을 보여줌
    }
    const today = new Date().toLocaleDateString();
    return popupClosedDate !== today; // 저장된 날짜와 현재 날짜가 다르면 팝업을 보여줌
  };
  const setPopupOpenTime = () => {
    const today = new Date().toLocaleDateString(); // 현재 날짜를 "yyyy-mm-dd" 형식으로 가져옴
    setLocalStorage('popupClosedDate', today);
  };
  return <Layout headerType={Contains.MAIN} wrapClassName={`tablet:pb-[56px]`} openSearchBar={openSearchBar} isHomeBarFixed={true}>
      <Head>
        <title>티티비비 TTBB</title>
      </Head>
      <MainWrapper>
        <SearchBox setOpenSearchBar={setOpenSearchBar} />
        {mainBannerData?.items && mainBannerData?.items.map((bannerData, index) => <React.Fragment key={index}>
              {bannerData?.items && bannerData?.items.length > 0 && <>
                  {/* mGroup2 */}
                  {bannerData.type === "LIST_D" && <ListD info={bannerData} />}
                  {/* mGroup3 */}
                  {bannerData?.type === "BANNER_A" && <BannerA info={bannerData} />}
                  {/* mGroup4 */}
                  {bannerData?.type === "LIST_A" && <ListA info={bannerData} />}
                  {/* mGroup5 */}
                  {bannerData.type === "LIST_C" && <ListC info={bannerData} />}
                  {/* 글로벌리어배너 */}
                  {bannerData.type === "BANNER_B" && <BannerB info={bannerData} />}
                  {/* mGroup9 */}
                  {bannerData.type === "LIST_E" && <ListE info={bannerData} />}
                  {/* mGroup10 */}
                  {bannerData.type === "BANNER_D" && <BannerD info={bannerData} />}
                  {/* mGroup6 */}
                  {bannerData.type === "BANNER_E" && <BannerE info={bannerData} />}
                  {/*mGroup13*/}
                  {bannerData?.type === "LIST_G" && <ListG info={bannerData} />}
                </>}
            </React.Fragment>)}
        {isTablet && <div className="mt-[47px] mb-[42px] flex justify-center">
              <TTBBFooterLogo />
            </div>}
      </MainWrapper>
      <CookieConsent />

      {popupOpen && <Popup className="max-w-[calc(36rem_-_80px)] p-[36px] !justify-start top-[7%]" innerClass="rounded-[16px]" customDim={<div></div>}>
          <div className="flex w-full flex-col items-start text-Gray-900 bg-Primary500">
            <h6 className="H6_18_145_Bold px-6 pt-6 text-[18px] font-bold text-Gray-900">
              {"트래블프리셋 카드 사용 안내"}
            </h6>
            <p className="P_15_160_Regular mt-2 px-6 text-Gray-800">
              {"TTBB 숙박 상품은 선불카드 금액 내 상품에 대해서 실시간 결제 가능합니다. (카드 2개 이상 합산 사용 및 유선 결제 불가)"}
            </p>
            <hr className="mt-5 w-full text-Gray-100" />
            <div className="flex flex-row w-full">
              {/*<button*/}
              {/*  className="B_16_145_Medium flex h-[56px] items-center justify-center text-Gray-500 basis-1/2"*/}
              {/*  onClick={() => {*/}
              {/*    setPopupOpen(false);*/}
              {/*    setPopupOpenTime();*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {"하루동안 보지않기"}*/}
              {/*</button>*/}
              {/*<div className="bg-Gray-50 w-[1px] h-[56px]"></div>*/}
              <button
          // className="B_16_145_Medium flex h-[56px] items-center justify-center text-Gray-900 basis-1/2"
          className="B_16_145_Medium flex h-[56px] items-center justify-center text-Gray-900 w-full" onClick={() => {
            setPopupOpen(false);
            setPopupOpenTime();
          }}>
                {"확인"}
              </button>
            </div>
          </div>
        </Popup>}
    </Layout>;
}
const MainWrapper = styled.main.withConfig({
  displayName: "pages__MainWrapper",
  componentId: "sc-1rsmyf1-0"
})(["section{", "}"], {
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms",
  "transitionDelay": "0.3s"
});