import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import Link from "next/link";
import React from "react";
import { Contains } from '@modules/utils/Contains';
import styles from "../Main.module.css";
import dayjs from "dayjs";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie } from '@modules/utils/SearchDataHandler';
import Image from "next/image";
export default function BannerD({
  info
}) {
  return <section className={`${styles.mGroup10} mGroup10 relative overflow-hidden mb-[128px] mobile:mb-[40px]`}>
      {info.items && <SwiperWrapper className="box">
          <Swiper modules={[Navigation, Pagination, Autoplay]} autoplay={info.items?.length > 1 ? {
        delay: 3500
      } : false} loop={info.items?.length > 1} slidesPerView="auto" pagination={{
        el: ".mGroup10 .swiper-pagination"
      }} navigation={{
        prevEl: ".mGroup10 .swiper-button-prev",
        nextEl: ".mGroup10 .swiper-button-next"
      }}>
            {info.items.map((item, index) => <SwiperSlide key={index} className={`bgVisualColor01`}>
                <Link href={{
            pathname: "/hotels/[id]",
            query: {
              id: item.hotel_id,
              "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY.MM.DD"),
              "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY.MM.DD"),
              ...getRoomQuery(getSearchRoomsCookie()),
              query: item.hotel_name,
              searchId: item.hotel_id,
              searchType: Contains.HOTEL
            }
          }} className="box block m-[0_auto] flex flex-col justify-center
                           tablet:w-[calc(100%_-_48px)] tablet:h-[396px] tablet:m-[0_24px]
                           mobile:w-full mobile:h-[200px] mobile:m-0">
                  <Image fill sizes="768" className="mobile:hidden w-full" src={item.main_image_url} onError={e => {
              e.target.src = "/images/common/img_default_3by4.png";
            }} alt="" />
                  <Image fill sizes="768" className="hidden mobile:block" src={item.thumbnail_url} onError={e => {
              e.target.src = "/images/common/img_default_3by4.png";
            }} alt="" />
                </Link>
              </SwiperSlide>)}
          </Swiper>
          <div className="swiper-pagination"></div>
          <div className={`${styles.swiperButtonPrev} swiper-button-prev !w-[48px] !h-[48px] !text-black !top-[308px] !left-1/2 ml-[-691px] after:!text-[0] 
            bg-ic_arrow_right_ou_b_g90 rotate-180 bg-center bg-no-repeat bg-[length:48px_48px] tablet:!hidden`} />
          <div className={`${styles.swiperButtonNext} swiper-button-next !w-[48px] !h-[48px] !text-black !top-[308px] !right-1/2 mr-[-691px] after:!text-[0] 
            bg-ic_arrow_right_ou_b_g90 bg-center bg-no-repeat bg-[length:48px_48px] tablet:!hidden`} />
        </SwiperWrapper>}
    </section>;
}
const SwiperWrapper = styled.div.withConfig({
  displayName: "BannerD__SwiperWrapper",
  componentId: "sc-df46fp-0"
})([".swiper{", " .swiper-slide{", "}}.swiper-pagination{", " .swiper-pagination-bullet{", "}.swiper-pagination-bullet:first-child{", "}.swiper-pagination-bullet-active{", "}}"], {}, {}, {
  "bottom": "20px",
  "width": "100%",
  "@media (max-width: 767px)": {
    "bottom": "7px"
  }
}, {
  "margin": "0 0 0 8px",
  "height": "8px",
  "width": "8px",
  "borderRadius": "100%",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity, 1))",
  "opacity": "0.25",
  "@media (max-width: 767px)": {
    "height": "6px",
    "width": "6px"
  }
}, {
  "marginLeft": "0px"
}, {
  "opacity": "1"
});