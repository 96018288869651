import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useTablet } from "@modules/hooks/useMedia";
import TTBBFooterLogo from "@public/images/common/img_tripbtoz.svg";
import { IsTTBBApp } from "@modules/utils/TripbtozApp";
export default function Footer({
  className
}) {
  const {
    t
  } = useTranslation("common");
  const isTablet = useTablet();
  const corpUrl = IsTTBBApp() ? `ttbb://external?url=${encodeURIComponent(process.env.NEXT_PUBLIC_CORP_URL)}` : process.env.NEXT_PUBLIC_CORP_URL;
  const target = IsTTBBApp() ? '_self' : '_blank';
  return <footer className={`relative block border-t-Bg-TintWhite border-t-[1px] border-solid transition-all delay-[0.3s] px-[24px] mobile:p-[0_16px] ${className} text-Gray-500`}>
      <div className={`footer flex flex-wrap items-center content-center m-[0_auto_0_auto] max-w-[1180px] py-[40px]
                    flex items-center justify-between`}>
        <div className="footer_text w-fit
                   tablet:p-0 tablet:p-[0_24px] tablet:w-full tablet:h-[205px]
                   mobile:p-[19px_0] mobile:w-full mobile:h-auto">
        {/* 공통 Start */}
          <ul className='w-full pb-[8px] text-Gray-500 C_12_100_Regular mobile:SC_11_100_Regular'>
            <li className={'inline-block pr-[12px]'}>
              <a href={corpUrl} target={target} className='block py-[10px]'>
                {t('footer.about.us.txt')}
              </a>
            </li>
            <li className={'inline-block pr-[12px]'}>
              <Link href={{
              pathname: '/terms/service',
              query: {
                key: 'main'
              }
            }}>
                {t('footer.btn.terms.person')}
              </Link>
            </li>
            <li className={'inline-block pr-[12px] SC_11_100_Bold'}>
              <Link href={{
              pathname: '/terms/privacy',
              query: {
                key: 'main'
              }
            }}>
                {t('footer.btn.terms.policy')}
              </Link>
            </li>
            <li className={'inline-block pr-[12px]'}>
              <Link href={{
              pathname: '/cmcenter/notice',
              query: {
                key: 'main'
              }
            }}>
                {t('setting.cmcenter.notice')}
              </Link>
            </li>
          </ul>
          {/* 공통 End */}

          {/* 웹 Start */}
          <div className='mobile:hidden space-y-2 w-full C_12_145_Regular'>
            <p>
              {t('footer.customer.center')} {t('footer.customer.center.ttbb.telnumber')}{' '}
          {t("footer.business.hours,weekday")}
          <BulletSpan>{t("footer.business.hours.weekend")}</BulletSpan>
        </p>
        <p>
          {t("footer.business.name")}
          <BulletSpan>{t("footer.business.ceo")}</BulletSpan>
          <BulletSpan>{t("footer.business.manager")}</BulletSpan>
          <BulletSpan>{t("footer.business.addr")}</BulletSpan>
        </p>
        <p>
          {t("footer.business.number")}{" "}
          <a className="C_12_145_Bold" href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7788600179&apv_perm_no=" target="_blank" rel="noreferrer">
            {t("footer.business.info")}
          </a>
          <BulletSpan>{t("footer.business.tour.number")}</BulletSpan>
          <BulletSpan>{t("footer.business.sale.text")}</BulletSpan>
        </p>
          </div>
        {/* 웹 End */}

        {/* 모바일 Start */}
        <p className="hidden mobile:block w-full pt-[0] pb-[8px] SC_11_145_Regular">
          {t("footer.customer.center")} {t("footer.customer.center.ttbb.telnumber")}
          <br />
          {t("footer.business.hours,weekday")}
          <BulletSpan>{t("footer.business.hours.weekend")}</BulletSpan>
        </p>
        <p className="hidden mobile:block w-full pt-0 pb-[2px] SC_11_145_Regular">
          {t("footer.business.name")}
          <em className="block pt-[2px]">
            {t("footer.business.ceo")}
            <BulletSpan>{t("footer.business.manager")}</BulletSpan>
          </em>
          <em className="block pt-[2px]">{t("footer.business.addr")}</em>
        </p>
        <p className="hidden mobile:block w-full pt-0 SC_11_145_Regular">
          {t("footer.business.number")}
          <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7788600179&apv_perm_no=" target="_blank" rel="noreferrer" className="SC_11_145_Bold ml-[8px] cursor-pointer">
            {t("footer.business.info")}
          </a>
          <em className="block pt-[2px]">{t("footer.business.tour.number")}</em>
          <em className="block pt-[2px]">{t("footer.business.sale.text")}</em>
        </p>
        {/* 모바일 End */}

        {/* 공통 Start */}
        <div className='w-full mt-[9px] mobile:mt-[8px] SC_11_145_Regular'>
        <p className="">
            {t("footer.business.support")}
        </p>
        <p className="mt-[8px] text-Gray-300">
          {t("footer.copyright")}
        </p>
        <p className="mt-5 text-Gray-500">
          {t("KB Pay 여행은 통신판매중개자로서 거래 당사자가 아니며, 등록한 상품 정보 및 거래에 대한 책임은 입점판매자에게 있습니다.")}
        </p>
        </div>
        {/* 공통 End */}
      </div>
        {!isTablet && <TTBBFooterLogo />}
      </div>
    </footer>;
}
const BulletSpan = ({
  children
}) => {
  return <span className={'pl-[6px] ml-[4px] inline-block relative before:content-[\'\'] before:absolute before:left-0 before:top-[7px] before:w-[2px] before:h-[2px] before:bg-Gray-300 rounded-[100px]'}>
        {children}
      </span>;
};